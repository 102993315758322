<template>
  <MDBContainer>
    <MDBRow class="row mt-3 g-3">
      <MDBCol md="8"
        ><img
          :src="cdnUrl + '/' + no + '.jpg'"
          class="img-fluid"
          alt="..."
      /></MDBCol>
      <MDBCol md="8" class="mb-3">
        <div class="d-grid gap-2 col-6 mx-auto">
          <MDBBtn
            tag="a"
            outline="secondary"
            :href="cdnUrl + '/' + no + '.pdf'"
            target="_blank"
            >Download pdf</MDBBtn
          >
          <MDBBtn
            tag="a"
            outline="secondary"
            :href="cdnUrl + '/' + no + '.jpg'"
            target="_blank"
            >Download jpg</MDBBtn
          >
        </div>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from "mdb-vue-ui-kit";

export default {
  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn,
  },
  name: "Siserkom",
  props: ["no"],
  data() {
    return {
      cdnUrl: `${process.env.VUE_APP_CDN_SISERKOM}`,
    };
  },
};
</script>