<template>
  <MDBContainer>
    <MDBRow class="row mt-3 g-3">
      <MDBCol md="8"
        ><img :src="cdnUrl + '/' + no + '.jpg'" class="img-fluid" alt="..."
      /></MDBCol>
      <MDBCol md="4">
        <dl class="row">
          <dt class="col-sm-6">KATEGORI</dt>
          <dd class="col-sm-6">
            {{ santri.isReguler ? "REGULER" : "NON-REGULER" }}
          </dd>

          <template v-for="item in santri.nilaiMataKuliahList" :key="item.idMataKuliahList">
            <dt class="col-sm-6" style="text-transform: capitalize">{{ item.namaMatakuliah }}</dt>
            <dd class="col-sm-6">{{ item.nilai }}</dd>
          </template>

          <hr />

          <dt class="col-sm-6">RATA-RATA</dt>
          <dd class="col-sm-6">{{ santri.rataRata }}</dd>

          <dt class="col-sm-6">NILAI</dt>
          <dd class="col-sm-6">{{ santri.nilaiHuruf }}</dd>
        </dl>
      </MDBCol>
      <MDBCol md="8" class="mb-3">
        <div class="d-grid gap-2 col-6 mx-auto">
          <MDBBtn
            tag="a"
            outline="secondary"
            :href="cdnUrl + '/' + no + '.pdf'"
            target="_blank"
            >Download pdf</MDBBtn
          >
          <MDBBtn
            tag="a"
            outline="secondary"
            :href="cdnUrl + '/' + no + '.jpg'"
            target="_blank"
            >Download jpg</MDBBtn
          >
        </div>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from "mdb-vue-ui-kit";

export default {
  components: {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBBtn,
  },
  name: "Mahad",
  props: ["no"],
  data() {
    return {
      santri: {},
      cdnUrl: `${process.env.VUE_APP_CDN_MAHAD}`,
    };
  },
  methods: {
    getSantri() {
      const url =
        `${process.env.VUE_APP_API_MAHAD}/santri/findByCertificateId?certificateId=` +
        this.no;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.santri = data;
        });
    },
  },
  created() {
    this.getSantri();
  },
};
</script>